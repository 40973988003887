
// navigator.serviceWorker.register('./firebase-messaging-sw.js')
//     .then((registration) => {
//         messaging.useServiceWorker(registration);
//     });

var messaging = null;
if ('function' === typeof importScripts) {
    importScripts("https://www.gstatic.com/firebasejs/9.1.3/firebase-app-compat.js");
    importScripts("https://www.gstatic.com/firebasejs/9.1.3/firebase-messaging-compat.js");
    firebase.initializeApp({
        apiKey: "AIzaSyBeoKZyq7d5bZCCoUA4MCOYR4v_slfc8GU",
        authDomain: "sienna-sphere-dev-fd580.firebaseapp.com",
        databaseURL: "https://sienna-sphere-dev-fd580-default-rtdb.europe-west1.firebasedatabase.app",
        projectId: "sienna-sphere-dev-fd580",
        storageBucket: "sienna-sphere-dev-fd580.appspot.com",
        messagingSenderId: "308354925899",
        appId: "1:308354925899:web:c3ebcdb21145ae54b614ce",
        measurementId: "G-FXGZFJCRWE",
    });
    messaging = firebase.messaging();
}
